<template>
    <div class="option_cgv">
        <h3>CGV edition et historique</h3>
        <br/>
        <!--<vs-divider/>-->
        <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
        <br/>
        <vs-row>
            <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
                <span></span>
                <vs-button color="success" type="filled" v-on:click="add()" >Enregistrer</vs-button>
            </vs-col>
        </vs-row>
        <br/>
        <br/>
        <vx-card>
            <h2>CGV Historique </h2>
            <vs-divider/>
            <vs-list>
                <vs-list-item v-for="item in list_histo_cgv" :key="item.uid" :title="GetFullDate(item.createAt)" >
                    <div class="click cgv_consu" v-on:click="view(item.createAt)">Consulter</div>
                </vs-list-item>
            </vs-list>
        </vx-card>
    </div>
</template>



<style lang="scss">
.option_cgv{
    .ck-content{
        height: 400px !important;
    }
    .cgv_consu{
       float:right;
       text-align:right;
       margin:right;
    }
}
</style>

<script>

import autoRefresh from '@/assets/utils/autoRefresh'
import Fdate from '@/assets/utils/fdate'

import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import CGV from '@/database/models/cgv'

export default {
    data(){
        return{
            editor: ClassicEditor,
            editorData: '',
            editorConfig: {
                // The configuration of the editor.
            },

            list_histo_cgv:[],
        }
	},
	mounted:function()
	{
        //autoRefresh
        autoRefresh.SetCallback(()=>
        {
            //reload car pas de log pour autoload
            CGV.load(()=>
            {
                //refresh
                CGV.getTabAllMemory((list)=>{
                    //triage CGV
                    list.sort(function(a, b)
                    {
                        let time_a = (new Date(a.createAt)).getTime();
                        let time_b = (new Date(b.createAt)).getTime();
                        return time_b - time_a;
                    })
                    this.list_histo_cgv = list
                })
            })
        })
        autoRefresh.refresh()

        //get cgv pour remplissage
        this.$srvApi.Req('get', 'studio/cgv/print')
        .then(( rep )=>{
            if( !rep.status )
                this.editorData = rep
        })
    },
    watch:{
        $route (){
            
        }
    },
    methods:{
        GetFullDate(date)
        {
            return Fdate.getFullDate(date);
        },
        add()
        {
            CGV.add({
                html:escape(this.editorData)
            })
            .then(()=>{
                //CGV non constultable ! ( err quoi qu'il arrive !)
                autoRefresh.refresh();
            })
            .catch(()=>{
                //CGV non constultable ! ( err quoi qu'il arrive !)
                autoRefresh.refresh();
            })
            alert('CGV crée !');
        },
        view( date )
        {
            let d = (new Date(date)).toISOString().slice(0, 19).split('T')[0]

            this.$router.push('/admin/file/'+ encodeURI(btoa('studio/cgv/print/'+d)))
        },
    }
};

</script>